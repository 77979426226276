import React, { Component } from "react"
import styles from "../../css/items.module.css"
import { Episode } from "./Episode"
import Title from "../Title"

export default class EpisodeList extends Component {
  state = {
    episodes: [],
    sortedEpisodes: [],
  }

  componentDidMount() {
    this.setState({
      episodes: this.props.episodes.edges,
      sortedEpisodes: this.props.episodes.edges,
    })
  }

  render() {
    return (
      <section className={styles.tours}>
        <Title title="yewwwtoob" subtitle="episodes" />
        <div className={styles.center}>
          {this.state.sortedEpisodes.map(({ node }) => {
            return <Episode key={node.contentful_id} episode={node} />
          })}
        </div>
      </section>
    )
  }
}
