import React from "react"
import EpisodeList from "./EpisodeList"
import { useStaticQuery, graphql } from "gatsby"

const getEpisodes = graphql`
  query {
    episodes: allContentfulEpisode {
      edges {
        node {
          contentful_id
          name
          slug
          url
          featured
          description {
            description
          }
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export const Episodes = () => {
  const { episodes } = useStaticQuery(getEpisodes)

  return <EpisodeList episodes={episodes} />
}
